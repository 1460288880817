import { createEffect, createSignal, JSX, Show } from "solid-js";
import { CloseModalButton } from "~/components/modal";
import { popPage } from "~/shared_states/modal";
import { createJob } from "~/utils/job";
import { Spacer } from "~/widgets/spacer";
import {
  ButtonDark,
  ModalDarkInnerFrame,
  ModalDarkOuterFrame,
} from "./components";
import { GiftBoxSendOtpResponse } from "~/server/types/gift";

export function CollectPhoneNumber(props: {
  submitPhoneNumber: (phoneNumber: string) => Promise<GiftBoxSendOtpResponse>;
  onValidPhoneNumberSubmitted: (phoneNumber: string, otpToken: string) => void;
  subtitle?: string;
}) {
  const [phoneNumber, setPhoneNumber] = createSignal<string>("");
  const [errorMessage, setErrorMessage] = createSignal<string | undefined>("");

  const [isFieldActive, setIsFieldActive] = createSignal(false);

  const submitPhoneNumberJob = createJob<GiftBoxSendOtpResponse>({
    errorCallback: (error) => {
      setErrorMessage(error.message);
    },
    initialJob: async () => {
      if (phoneNumber().length != 10) {
        throw new Error("Please enter a 10 digit phone number");
      }
      return props.submitPhoneNumber(phoneNumber());
    },
    successCallback: async (response) => {
      return props.onValidPhoneNumberSubmitted(
        phoneNumber(),
        response.otpToken
      );
    },
  });

  let phoneInput: HTMLInputElement | undefined;
  createEffect(() => {
    phoneInput?.focus();
  });

  createEffect(() => {
    phoneNumber();
    setErrorMessage(undefined);
  });

  return (
    <ModalDarkOuterFrame>
      <ModalDarkInnerFrame>
        <div class="dark absolute right-4 top-4 sm:right-6 sm:top-6">
          <CloseModalButton
            onClick={() => {
              popPage();
            }}
          />
        </div>
        {buildTitleAndSubtitle()}
        <div class="h-5 sm:h-6" />
        {buildInputField()}
        <Spacer height={32} />
        <ButtonDark
          onClick={() => {
            submitPhoneNumberJob.run();
          }}
          isLoading={submitPhoneNumberJob.jobState() == "running"}
        >
          Get OTP
        </ButtonDark>
      </ModalDarkInnerFrame>
    </ModalDarkOuterFrame>
  );

  function buildTitleAndSubtitle() {
    return (
      <div class="flex flex-col items-start">
        <span class="text-center text-h4 text-baseTertiaryLight">
          Your phone number
        </span>
        <Show when={props.subtitle}>
          <Spacer height={4} />
          <span class="text-medium text-textNormal">{props.subtitle!}</span>
        </Show>
      </div>
    );
  }

  function buildInputField() {
    return (
      <div class="flex flex-col">
        <div
          class="rounded-xl border px-4 py-[13px] transition-colors"
          classList={{
            "border-baseTertiaryLight": isFieldActive(),
            "border-baseSecondaryMedium": !isFieldActive(),
            "border-errorDark": !!errorMessage(),
          }}
        >
          <form
            class="text flex text-bold text-baseTertiaryLight"
            onSubmit={(e) => {
              e.preventDefault();
              submitPhoneNumberJob.run();
            }}
          >
            <div class="flex flex-row">
              <span class="text-baseTertiaryDark">{"+91"}</span>
              <Spacer width={12} />
              <input
                class="flex-1 bg-transparent text-baseTertiaryLight placeholder:text-normal placeholder:text-textNormal"
                ref={phoneInput}
                placeholder="Enter phone number"
                value={phoneNumber()}
                onInput={(e) => setPhoneNumber(e.currentTarget.value)}
                type="tel"
                maxlength={10}
                onFocus={() => setIsFieldActive(true)}
                onBlur={() => setIsFieldActive(false)}
              />
            </div>
          </form>
        </div>
        <Show when={errorMessage()}>
          <Spacer height={4} />
          <span class="text-medium  text-errorDark">{errorMessage()}</span>
        </Show>
      </div>
    );
  }
}
